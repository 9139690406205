body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav.navbar.navbar-dark {
  color: #fff !important;
}

#searchForm {
  border-bottom: 1px solid #000;
}

.searchFormDown {
  -webkit-transition: all ease-out 0.25s;
  -moz-transition: all ease-out 0.25s;
  -o-transition: all ease-out 0.25s;
  transition: all ease-out 0.25s;
}

.card.btn {
}

.card.btn:hover {
  background-color: #fc4e032a;
  border: 1px solid #fc4e03;
}

.card-f {
  max-height: 100% !important;
  height: 90px;
}

.list-container {
  width: 100%;
  height: auto;
}

.tab-content {
  height: 512px !important;
}

.nav-link.active {
  background: linear-gradient(to bottom, #fc8803, #fc4e03);
}
.nav-link:not(.active) {
  padding-bottom: 0;
}
.nav-link p {
  margin-bottom: 4px;
}
.nav-link div {
  height: 4px;
  width: 0%;
  background-color: #fc4e03;
  transition: width 0.2s ease;
}

.nav-link:hover div {
  width: 100%;
}

span.nav-link {
  border: 1px solid transparent;
}
span.nav-link:hover {
  cursor: pointer;
  background-color: #fc4e032a !important;
  border: 1px solid #fc4e03;
}

@media only screen and (max-width: 994px) {
  .list-container {
    overflow: scroll;
  }
  .h3 {
    font-size: 1rem !important;
  }
}
@media only screen and (max-width: 768px) {
}

.emoji {
  height: 3ch !important;
  transition: all .5s ease;
}

.emoji:hover {
  transform: translateY(-5px);
}

.alert {
  border: 1px solid rgba(0,0,0,.125) !important;
}
.btn.feedback {
  border: 1px solid rgba(0,0,0,.125) !important;
}
.btn.feedback:hover {
  background-color: #fc4e032a !important;
  border: 1px solid #fc4e03 !important;
}

.navbar-brand {
}